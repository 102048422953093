































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import EventTable from '@/components/custom-events/EventTable.vue';
import EmptyPlaceholder from '@/components/common/EmptyPlaceholder.vue';
import { CustomEventDetails } from '@/models/custom-event';
import { EventStores } from '@/store/events';
import { PaginationDetails } from '@/models/generic';
import PaginationControls from '@/components/common/PaginationControls.vue';
import SearchBar from '@/components/common/SearchBar.vue';

@Component({
  components: {
    Page,
    EventTable,
    EmptyPlaceholder,
    PaginationControls,
    SearchBar,
  },
})
export default class EventManagementPage extends Vue {
  eventStore = EventStores;

  currentPage = 1;
  currentLimit = 10;
  searchText = '';

  get events() {
    return this.eventStore?.events;
  }

  mounted() {
    this.fetchData();
  }

  fetchData() {
    this.eventStore.getEvents({
      page: this.currentPage,
      limit: this.currentLimit,
      keyword: this.searchText,
    });
  }

  onChangePage(params: PaginationDetails) {
    this.currentPage = params.page;
    this.currentLimit = params.limit;
    this.fetchData();
  }

  handleSearch(keyword: string) {
    this.searchText = keyword;
    this.fetchData();
  }

  handleEdit(data: CustomEventDetails) {
    this.$router.push({ path: `events/${data.id}/edit` });
  }

  handleDelete(data: CustomEventDetails) {
    this.$buefy.dialog.confirm({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete <b>${data.name}</b>?`,
      onConfirm: () => {
        this.eventStore.deleteEvent(data.id);
      },
    });
  }

  @Watch('eventStore.isDeletingEvent')
  onIsDeletingEventChange(isDeletingEvent: boolean) {
    if (!isDeletingEvent) {
      this.fetchData();
    }
  }
}
