import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  CreateCustomEventFormPayload,
  UpdateCustomEventFormPayload,
  CustomEventResponse,
  CustomEventsResponse,
  PaginatedCustomEvents,
} from '@/models/custom-event';

const defaultPaginatedEvents = {
  data: null,
  page: 1,
  limit: 10,
  totalRecords: 0,
  totalPages: 0,
};

@Module({
  namespaced: true,
  dynamic: true,
  name: `event`,
  store,
})
class EventStore extends VuexModule {
  isPendingEvents = false;
  isPendingEvent = false;
  isCreatingEvent = false;
  isUpdatingEvent = false;
  isDeletingEvent = false;

  eventsError: any = null;
  eventError: any = null;
  createEventError: any = null;
  updateEventError: any = null;
  deleteEventError: any = null;
  events: PaginatedCustomEvents = {
    ...defaultPaginatedEvents,
  };
  event: CustomEventResponse['data'] | null = null;

  @Mutation
  GET_EVENTS_LOADING() {
    this.isPendingEvents = true;
    this.eventsError = null;
    this.events = defaultPaginatedEvents;
  }
  @Mutation
  GET_EVENTS_SUCCESS(payload: PaginatedCustomEvents) {
    this.isPendingEvents = false;
    this.events = {
      ...this.events,
      ...payload,
    };
  }
  @Mutation
  GET_EVENTS_ERROR(payload: any) {
    this.isPendingEvents = false;
    this.eventsError = payload;
  }

  @Mutation
  GET_EVENT_LOADING() {
    this.isPendingEvent = true;
    this.eventError = null;
    this.event = null;
  }
  @Mutation
  GET_EVENT_SUCCESS(payload: CustomEventResponse['data']) {
    this.isPendingEvent = false;
    this.event = payload;
  }
  @Mutation
  GET_EVENT_ERROR(payload: any) {
    this.isPendingEvent = false;
    this.eventError = payload;
  }

  @Mutation
  CREATE_EVENT_LOADING() {
    this.isCreatingEvent = true;
    this.createEventError = null;
  }
  @Mutation
  CREATE_EVENT_SUCCESS(payload: any) {
    this.isCreatingEvent = false;
  }
  @Mutation
  CREATE_EVENTS_ERROR(payload: any) {
    this.isCreatingEvent = false;
    this.createEventError = payload;
  }

  @Mutation
  UPDATE_EVENT_LOADING() {
    this.isUpdatingEvent = true;
    this.updateEventError = null;
  }
  @Mutation
  UPDATE_EVENT_SUCCESS() {
    this.isUpdatingEvent = false;
  }
  @Mutation
  UPDATE_EVENTS_ERROR(payload: any) {
    this.isUpdatingEvent = false;
    this.updateEventError = payload;
  }

  @Mutation
  DELETE_EVENT_LOADING() {
    this.isDeletingEvent = true;
    this.deleteEventError = null;
  }
  @Mutation
  DELETE_EVENT_SUCCESS() {
    this.isDeletingEvent = false;
  }
  @Mutation
  DELETE_EVENTS_ERROR(payload: any) {
    this.isDeletingEvent = false;
    this.deleteEventError = payload;
  }

  @Action
  getEvents({ keyword = '', page = 1, limit = 10 }) {
    this.context.commit(`GET_EVENTS_LOADING`);

    axios
      .get<CustomEventsResponse>(`/custom-event/list`, {
        params: {
          type: 'event',
          keyword,
          page,
          limit,
        },
      })
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_EVENTS_SUCCESS`, {
            data: response.data.data,
            page: response.headers[`x-page`],
            limit: response.headers[`x-limit`],
            totalRecords: response.headers[`x-total-records`],
            totalPages: response.headers[`x-total-pages`],
          });
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching events data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`GET_EVENTS_ERROR`, error);
      });
  }

  @Action
  getEvent(id: number) {
    this.context.commit(`GET_EVENT_LOADING`);

    axios
      .get<CustomEventResponse>(`/custom-event/list/${id}`)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`GET_EVENT_SUCCESS`, response.data.data);
        }
        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching events data. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`GET_EVENT_ERROR`, error);
      });
  }

  @Action
  createEvent(params: CreateCustomEventFormPayload) {
    this.context.commit(`CREATE_EVENT_LOADING`);

    axios
      .post<CustomEventResponse>(`/custom-event/event`, params)
      .then((response) => {
        if (typeof response.data.data !== 'boolean' && response.data.code === 200) {
          this.context.commit(`CREATE_EVENT_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created event.`,
          });
        }

        if (response.data.code >= 400) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating events data. Please try again`,
          });

          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`CREATE_EVENT_ERROR`, error);
      });
  }

  @Action
  updateEvent(payload: UpdateCustomEventFormPayload) {
    this.context.commit(`UPDATE_EVENT_LOADING`);

    axios
      .put<CustomEventResponse>(
        `/custom-event/event/${payload.id}`,
        { ...payload },
        { timeout: 60000 }
      )
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`UPDATE_EVENT_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-success`,
            duration: 3000,
            message: `Event successfully update!`,
          });
        }

        if (response.data && response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000,
            message: `There is an issue with event update process. Please try again. Error: ${response.data.message}`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`UPDATE_EVENT_ERROR`, error);
      });
  }

  @Action
  deleteEvent(id: number) {
    this.context.commit(`DELETE_EVENT_LOADING`);

    axios
      .delete(`/custom-event/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`DELETE_EVENT_SUCCESS`, response.data.data);

          Toast.open({
            type: `is-info`,
            duration: 3000,
            message: `Event successfully deleted!`,
          });
        }

        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with event delete process. Please try again`,
          });
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        this.context.commit(`DELETE_EVENTS_ERROR`, error);
      });
  }
}

export const EventStores = getModule(EventStore);
